import React from 'react'
import Ruler from '~components/Ruler'

import './index.scss'
import useMediaQuery from '~hooks/useMediaQuery'
import useIntersectionObserver from '~components/global/IntersectionObserver'

const ImageWrapper = ({ image }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  return (
    <div className="careers-benefits-perks__image--wrapper" ref={componentRef}>
      <img className="careers-benefits-perks__image" alt="" src={image} />
    </div>
  )
}
const BenefitPerkList = ({ list, side, startIndex = 0 }) => {
  return (
    <ul className={`careers-benefits-perks__items careers-benefits-perks__items--${side}`}>
      {
        list.map((item, index) =>
          <li
            className="careers-benefits-perks__items__item"
            key={`benefit-perk-${side}-${index}`}
            style={{
              transitionDelay: side === 'left' ? `${.05 * (index + 1)}s` : `${.05 * (index + startIndex + 1)}s`
            }}
          >
            {item.item}
          </li>
        )
      }
    </ul>
  )
}

const CareersBenefitsPerks = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  const rulerData = {
    leftText: {
      text: data.title,
      type: 'heading',
      color: 'black'
    }
  }

  const isMobile = useMediaQuery('(max-width: 768px)')
  const listSplitIndex = isMobile ? Math.floor(data.list.length * (2/3)) : Math.ceil(data.list.length / 2)

  const listSubsetOne = data.list.slice(0, listSplitIndex)
  const listSubsetTwo = data.list.slice(listSplitIndex, data.list.length)

  return (
    <section className="careers-benefits-perks" ref={componentRef}>
      <div className="careers-benefits-perks__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        <div className="careers-benefits-perks__content">
          <p className="careers-benefits-perks__text">{data.text}</p>
          <div className="careers-benefits-perks__items--container">
            <BenefitPerkList list={listSubsetOne} side={'left'} />
            <BenefitPerkList list={listSubsetTwo} side={'right'} startIndex={listSubsetOne.length}/>
          </div>
          <ImageWrapper image={data.image} />
        </div>
      </div>
    </section>
  )
}

export default CareersBenefitsPerks
