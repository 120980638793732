import React from 'react'

import Layout from '~global/Layout'
import CareersPageWrap from '~pageWraps/CareersPageWrap'

import data from '../content/careers.yaml'

const Careers = () => {
  return (
    <Layout>
      <CareersPageWrap data={data}/>
    </Layout>
  )
}

export default Careers
