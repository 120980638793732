import React from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const ImageWrapper = ({ image }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  return (
    <div className="careers-team__image--wrapper" ref={componentRef}>
      <img className="careers-team__image" src={image} alt="" />
    </div>
  )
}

const CareersTeam = ({ data }) => {
  return (
    <section className="careers-team">
      <div className="careers-team__container">
        <div className="careers-team__images">
          <ImageWrapper image={data.image_1} />
          <ImageWrapper image={data.image_2} />
        </div>
      </div>
    </section>
  )
}

export default CareersTeam
