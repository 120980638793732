import React from 'react'

import Header from '~components/Header'
import Footer from '~components/Footer'

import CareersHero from '~components/pages/careers/Hero'
import CareersTeam from '~components/pages/careers/Team'
import CareersBenefitsPerks from '~components/pages/careers/BenefitsPerks'
import CareersOpenPositions from '~components/pages/careers/OpenPositions'

const CareersPageWrap = ({ data, isPreview }) => {
  return (
    <>
      <Header isPreview={isPreview} />
      <CareersHero data={data.hero} isPreview={isPreview} />
      <CareersTeam data={data.team} isPreview={isPreview} />
      <CareersBenefitsPerks data={data.benefits_perks} isPreview={isPreview} />
      <CareersOpenPositions data={data.open_positions} isPreview={isPreview} />
      <Footer isPreview={isPreview} />
    </>
  )
}

export default CareersPageWrap
