import React from 'react'
import Button from '~components/Button'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const CareersHero = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  const buttonData = {
    tag: 'button',
    text: data.button_text
  }

  return (
    <section className="careers-hero" ref={componentRef}>
      <div className="careers-hero__container">
        <h1 className="careers-hero__title">{data.title}</h1>
        <p className="careers-hero__text">{data.text}</p>
        <div className="button--wrapper">
          <Button data={buttonData} isPreview={isPreview} />
        </div>
      </div>
    </section>
  )
}

export default CareersHero
