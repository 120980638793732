import React from 'react'
import Ruler from '~components/Ruler'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const OpenPosition = ({ position, index }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-10%",
    threshold: 0
  })

  const positionRuler = (positionData) => {
    const positionRulerData = {
      rightText: {
        tag: 'a',
        text: 'Apply',
        url: positionData.position_link
      }
    }
    return (
      <Ruler data={positionRulerData} />
    )
  }

  return (
    <div
      className="careers-open-positions__position"
      ref={componentRef}
    >
      <div className="careers-open-positions__position__header">
        <h3 className="careers-open-positions__position__title">{position.position_title}</h3>
        <p className="careers-open-positions__position__location">{position.position_location}</p>
      </div>
      <p className="careers-open-positions__position__description">{position.position_description}</p>
      {positionRuler(position)}
    </div>
  )
}

const CareersOpenPositions = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  const rulerData = {
    leftText: {
      text: data.title,
      type: 'heading',
      color: 'black'
    }
  }

  const positions = data.positions.map((position, index) =>
    <OpenPosition position={position} key={`position-${index}`} />
  )

  return (
    <section className="careers-open-positions" ref={componentRef}>
      <div className="careers-open-positions__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        {positions}
      </div>
    </section>
  )
}

export default CareersOpenPositions
